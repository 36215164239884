	/*
  	Flaticon icon font: Flaticon
  	Creation date: 04/01/2019 13:45
  	*/


@font-face {
  font-family: "Flaticon";
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-ideas:before { content: "\f100"; }
.flaticon-flasks:before { content: "\f101"; }
.flaticon-analysis:before { content: "\f102"; }
.flaticon-ux-design:before { content: "\f103"; }
.flaticon-web-design:before { content: "\f104"; }
.flaticon-idea:before { content: "\f105"; }
.flaticon-innovation:before { content: "\f106"; }